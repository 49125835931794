
























































import { Vue, Component, Prop } from "vue-property-decorator";

interface RegulationOptions {
  vesselId: number;
  year: number;
  totalComplianceBalance: number;
  totalEmittedCo2: number;
  totalEmittedN2O: number;
  totalEmittedCh4: number;
  totalEuas: number;
  totalPenalty: number;
  avgGhgIntensity: number;
  requiredGhgIntensity: number;
  diffinGhgIntensity: number;
}

@Component({})
export default class RegulationCard extends Vue {
  @Prop() options!: RegulationOptions;
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop() title!: string;
  @Prop() type!: string;

  //  @Getters
  get CardData(): any[] | undefined {
    if (!this.options) return;
    switch (this.type) {
      case "mrv":
        return [
          {
            text: "Total YTD CO2 Emissions:",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalEmittedCo2,
          },
          {
            text: "Total YTD N20 Emissions:",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalEmittedN2O,
          },
          {
            text: "Total YTD CH4 Emissions:",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalEmittedCh4,
          },
        ];
      case "ets":
        return [
          {
            text: "Total YTD EUAs:",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalEuas,
          },
        ];
      case "fuel":
        return [
          {
            text: "YTD Avg. GHG Intensity:",
            tooltip: "Tooltip text placeholder",
            value: this.options.avgGhgIntensity.toFixed(2),
          },
          {
            text: "Required GHG Intensity:",
            tooltip: "Tooltip text placeholder",
            value: this.options.requiredGhgIntensity.toFixed(2),
          },
          {
            text: "Compliance Balance (gCO2e):",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalComplianceBalance.toFixed(2),
          },
          {
            text: "Penalty (Euros):",
            tooltip: "Tooltip text placeholder",
            value: this.options.totalPenalty.toFixed(2),
          },
        ];
      default:
        return [];
    }
  }

  get skeletonStructure(): object {
    return { "table-row": `table-cell@1` };
  }
}
