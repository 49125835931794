












































































































import { Component, Prop, Vue } from "vue-property-decorator";
//  types
import { DataQualityHeader, DataQualityRow } from "@/types/dataQuality/dataQualityTable";
//  components
import DataCell from "./DataCell.vue";

@Component({
  components: {
    DataCell,
  },
})
export default class VoyageSummaryTable extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: Boolean;
  @Prop({ type: Array, default: () => [] }) headers!: DataQualityHeader[];
  @Prop({ type: Array, default: () => [] }) rows!: DataQualityRow[];
  @Prop({ type: Number }) requiredGhgIntensity!: number;

  sortBy: string | null = "feature.value";
  sortDesc: boolean | null = false;
  pageLimit = 10;

  //  @Getters
  get isLessThanLimit(): boolean {
    return this.rows.length <= this.pageLimit;
  }

  get skeletonStructure(): object {
    return { "table-row": `table-cell@10` };
  }

  get sortedRows() {
    if (this.sortBy === null) {
      return this.rows;
    }

    return this.rows.slice().sort((a, b) => {
      const sortA = a[this.sortBy as any]?.value || "";
      const sortB = b[this.sortBy as any]?.value || "";
      if (this.sortDesc) {
        return sortA < sortB ? 1 : -1;
      } else {
        return sortA > sortB ? 1 : -1;
      }
    });
  }

  //  @Methods
  cellData(row: DataQualityRow, header: DataQualityHeader, index: number): any {
    return {
      column: header.value,
      columnName: header.text,
      index: index,
      rowIndex: row.key,
      type: header.type === "string" ? "string" : row[header.value].type,
      align: header.align,
      value: row[header.value].value,
      requiredGhgIntensity: this.requiredGhgIntensity,
    };
  }

  async downloadReport(filename: string) {
    this.$emit("onDownloadReport", filename);
  }

  sort(value: string) {
    if (this.sortBy === value) {
      if (this.sortDesc === null) {
        this.sortDesc = false;
      } else if (this.sortDesc === false) {
        this.sortDesc = true;
      } else {
        this.sortBy = null;
        this.sortDesc = null;
      }
    } else {
      this.sortBy = value;
      this.sortDesc = false;
    }
  }

  isDataTagORVariableMultiple(item: DataQualityRow): boolean {
    return item.datatag.type === "multiple" || item.variable.type === "multiple";
  }

  headerClasses(header: any): string[] {
    return [
      header.class,
      {
        sortable: header.sortable,
        "no-sort": this.sortBy !== header.value,
      },
    ];
  }

  headerStyles(header: any): any {
    return {
      width: header.width ? header.width : "auto",
      "min-width": header.width ? header.width : "auto",
    };
  }
}
