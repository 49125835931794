





























































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import moment from "moment";
//  types
import { ExtendedVessel } from "@/types/Vessel";
//  components
import VoyageSummaryTable from "@/components/EmmisionsCompliance/VoyageSummaryTable/index.vue";
import RegulationCard from "@/components/EmmisionsCompliance/RegulationCard.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";

//  modules
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/clients/Snackbar.module";
import VesselsModule from "@/store/clients/Vessels.module";
import EmissionsComplianceModule from "@/store/clients/EmissionsCompliance.module";
import UserModule from "@/store/clients/User.module";

const Snackbar = getModule(SnackbarModule, store);
const Vessels = getModule(VesselsModule, store);
const Emissions = getModule(EmissionsComplianceModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {
    VoyageSummaryTable,
    RegulationCard,
    FeatureNotAvailable,
  },
})
export default class EmissionsCompliance extends Vue {
  headers = [
    {
      text: "Voyage Id",
      type: "string",
      align: "left",
      value: "voyageId",
      width: "110px",
      sortable: true,
    },
    {
      text: "Departure / Arrival Port",
      type: "string",
      align: "left",
      value: "ports",
      width: "180px",
      sortable: true,
    },
    {
      text: "Condition",
      type: "string",
      align: "left",
      value: "condition",
      width: "110px",
      sortable: true,
    },
    {
      text: "Type",
      type: "string",
      align: "left",
      value: "voyageType",
      width: "110px",
      sortable: true,
    },
    {
      text: "Voyage Timeframe",
      subtitle: "(UTC)",
      type: "string",
      align: "left",
      value: "voyageTimeframe",
      width: "200px",
      sortable: true,
    },
    {
      text: "Distance Traveled",
      subtitle: "(NM)",
      type: "string",
      align: "left",
      value: "distanceTravelled",
      width: "150px",
      sortable: true,
    },
    {
      text: "GHG Intensity",
      subtitle: "(gCO e/MJ)",
      type: "string",
      align: "left",
      value: "actualGhcIntensity",
      width: "130px",
      sortable: true,
    },
    {
      text: "EUAs",
      type: "string",
      align: "left",
      value: "euAs",
      width: "100px",
      sortable: true,
    },
    {
      text: "Balance",
      type: "string",
      align: "left",
      value: "complianceBalance",
      width: "100px",
      sortable: true,
    },
    {
      text: "Penalty",
      type: "string",
      align: "left",
      value: "penalty",
      width: "100px",
      sortable: true,
    },
    {
      text: "#DQ Issues",
      type: "string",
      align: "left",
      value: "dqIssues",
      width: "115px",
      sortable: true,
    },
    {
      text: "Report",
      type: "string",
      align: "left",
      value: "report",
      width: "70px",
      sortable: false,
    },
  ];
  files: any[] = [];
  uploadingFiles = false;

  //  @Getters
  get isSuperOrAdmin(): boolean {
    return User.isSuperOrAdmin;
  }

  get isEmissionComplianceFeatureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "EmissionCompliance");
  }

  get vessel(): ExtendedVessel | null {
    if (!Vessels.currentVessel) return null;
    return Vessels.currentVessel;
  }

  get loadingState(): boolean {
    return Emissions.loadingState;
  }

  get loadingEmissionSummaryState(): boolean {
    return Emissions.loadingEmissionSummaryState;
  }

  get voyageList(): any[] {
    return Emissions.voyageList;
  }

  get emissionSummary(): any[] {
    return Emissions.emissionSummary[Emissions.emissionSummary.length - 1];
  }
  get requiredGhgIntensity(): number {
    return Emissions.emissionSummary[Emissions.emissionSummary.length - 1]?.requiredGhgIntensity ?? 0;
  }

  get rows(): any {
    if (!this.voyageList) return [];
    const rows = this.voyageList.map((item, index) => ({
      key: index,
      voyageId: {
        value: item.voyageId,
      },
      ports: {
        value: `${item.portOfDeparture}, ${item.portOfArrival}`,
        departure: item.portOfDeparture,
        arrival: item.portOfArrival,
      },
      condition: {
        value: item.condition,
      },
      voyageType: {
        value: item.voyageType,
      },
      voyageTimeframe: {
        value: `${item.departureTime}, ${item.arrivalTime}`,
        departure: moment(item.departureTime).format("MMMM Do YYYY, h:mm a"),
        arrival: moment(item.arrivalTime).format("MMMM Do YYYY, h:mm a"),
      },
      departureTime: {
        value: moment(item.departureTime).format("MMMM Do YYYY, h:mm a"),
      },
      arrivalTime: {
        value: moment(item.arrivalTime).format("MMMM Do YYYY, h:mm a"),
      },
      distanceTravelled: {
        value: item.distanceTravelled,
      },
      actualGhcIntensity: {
        value: item.actualGhcIntensity,
      },
      euAs: {
        value: item.euAs,
      },
      complianceBalance: {
        value: item.complianceBalance ?? 0,
      },
      penalty: {
        value: item.penalty ?? 0,
      },
      dqIssues: {
        value: item.dqIssues,
      },
      report: {
        value: item.filename ?? "N/A",
      },
    }));

    return rows;
  }

  //  @Methods
  async fetchData(): Promise<void> {
    if (!this.vessel) return;

    try {
      await Emissions.fetchVoyageList(this.vessel?.id);
      await Emissions.fetchEmissionSummary(this.vessel?.id);
    } catch (e) {
      Snackbar.showSnackbar({ text: "Failed to load voyage and emission data" });
    }
  }

  async downloadReport(filename: string): Promise<void> {
    if (!this.vessel) return;
    await Emissions.downloadReport({ vesselId: this.vessel.id, filename: filename });
  }

  async uploadFiles(): Promise<void> {
    if (this.files.length > 0) {
      const formData = new FormData();

      // files
      for (const file of this.files) {
        formData.append("files", file, file.name);
      }
      if (!this.vessel) return;
      formData.append("vesselId", this.vessel.id.toString());
      try {
        this.uploadingFiles = true;
        const response = await Emissions.uploadFiles(formData);
        this.displaySnackbarMessages(response);
        await this.fetchData();
      } catch (err) {
        Snackbar.showSnackbar({ text: "An error occurred while uploading" });
      } finally {
        this.files = [];
        this.uploadingFiles = false;
      }
    }
  }

  displaySnackbarMessages(response: { files: []; skippedFiles: [] }): void {
    if (response.files.length && response.skippedFiles.length === 0) Snackbar.showSnackbar({ text: "All file(s) uploaded successfully", color: "success" });
    else if (response.files.length && response.skippedFiles.length) {
      Snackbar.showSnackbar({ text: `File(s) uploaded successfully:<br /> ${response.files.join("<br />")}`, color: "success", timeout: 5000 });
      setTimeout(() => {
        Snackbar.showSnackbar({ text: `File(s) were skipped<br /> ${response.skippedFiles.join("<br />")}`, color: "warning100", timeout: 7000 });
      }, 5000);
    } else if (response.files.length === 0 && response.skippedFiles.length > 0) {
      Snackbar.showSnackbar({ text: `File(s) were skipped:<br /> ${response.skippedFiles.join("<br />")}`, color: "warning100", timeout: 7000 });
    } else Snackbar.showSnackbar({ text: "No file(s) uploaded" });
  }

  //  @Hooks
  async created(): Promise<void> {
    await this.fetchData();
  }
}
