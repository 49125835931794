




































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
//  types
import { CellData } from "@/types/dataQuality/dataQualityTable";

@Component({
  components: {},
})
export default class DataCell extends Vue {
  @Prop({ type: Object }) data!: CellData;

  //  @Getters
  get isIndicator(): boolean {
    if (!this.data) return false;
    return this.data.type === "dataIndicator";
  }

  get isValueHighlighted(): boolean {
    if (!this.data || !this.data.requiredGhgIntensity) return false;
    if (this.isActualGhcIntensity) return Number(this.data.value) > this.data.requiredGhgIntensity;
    if (this.isDqIssues) return Number(this.data.value) > 0;
    return false;
  }

  get isReport(): boolean {
    if (!this.data) return false;
    return this.data.column === "report";
  }

  get isPorts(): boolean {
    if (!this.data) return false;
    return this.data.column === "ports";
  }

  get isVoyageTimeframe(): boolean {
    if (!this.data) return false;
    return this.data.column === "voyageTimeframe";
  }

  get isActualGhcIntensity(): boolean {
    if (!this.data) return false;
    return this.data.column === "actualGhcIntensity";
  }

  get isDqIssues(): boolean {
    if (!this.data) return false;
    return this.data.column === "dqIssues";
  }

  //  @Methods
  async downloadReport() {
    this.$emit("downloadReport", this.data.value);
  }

  splitStringByComma(string: string | number): string[] | string {
    if (typeof string === "string") return string.trim().split(/\s*,\s*/);
    return "N/A";
  }

  stringifyDate(date: string): string {
    return moment.utc(date).format("DD. MMM, YYYY HH:mm");
  }
}
